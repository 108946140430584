<template>
  <div class="modal-body">
    Before I start using Rethink Finance, I confirm that:

    <ul>
      <li>
        I am aware that even though Rethink Finance has been audited, it has not been battle tested in a live environment yet, so I will use it at my own risk.
      </li>
    </ul>

    <p>If you cannot confirm all of the above, please leave this website.</p>
  </div>
</template>

<script>
export default {
  name: "ComplianceModalBody"
}
</script>

<style>

</style>