import { render, staticRenderFns } from "./AddIlliquidUpdate.vue?vue&type=template&id=d681a9aa"
import script from "./AddIlliquidUpdate.vue?vue&type=script&lang=js"
export * from "./AddIlliquidUpdate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports