<template>
  <div class="text-center text-white mt-5 mb-5">
    <p>
      <a target="_blank" href="https://discord.gg/dB9yaEf5YQ">Discord</a> |
      <a target="_blank" href="https://github.com/rethink-finance">Github</a> |
      <a target="_blank" href="https://twitter.com/RethinkProtocol">Twitter</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style>

</style>